import {CookieUtil} from "@pmcms/utils/http/CookieUtil";

interface CookieCodeDefinition {
	key: string;
	type: string;
	title: string;
	description: object;
	code: Function;
}

class CookieManager
{
	cookiesAllowed: string[];
	codesExecuted: string[];
	chatButtonHidden: boolean;

	constructor(private $cookiePopupElement, private $cookiePopupBackgroundElement) {

		this.codesExecuted = [];
		this.chatButtonHidden = false;

		//	Cookie-Einstellungen ändern - Button in Datenschutz
		jQuery(document).on('click', '#change_cookie_button', (event) => {
			event.preventDefault();
			this.showPopup();
		});

		//	Speichern (alle)
		$cookiePopupElement.find('button.c_m_p_accept_all').on('click', (event) => {
			this.$cookiePopupElement.find('.c_m_p_details_type_contents input[type="checkbox"]').prop('checked', true);
			this.saveCookies();
		});

		//	Speichern (essenziell)
		$cookiePopupElement.find('button.c_m_p_accept_ess').on('click', (event) => {
			this.saveCookies();
		});

		//	Speichern (individuell)
		$cookiePopupElement.find('button.c_m_p_save').on('click', (event) => {
			this.saveCookies();
		});

		//	Cookie-Einstellungen
		$cookiePopupElement.find('button.c_m_p_infos').on('click', (event) => {
			this.$cookiePopupElement.find('#c_m_p_start').hide();
			this.$cookiePopupElement.find('#c_m_p_details').show();
		});

		//	Essenzielle Cookies readonly
		jQuery(document).on('click', '#onoffswitch_E, #onoffswitch_PHPSESSION', function(event){
			event.preventDefault();
			return false;
		});

		//	Alle Cookies aktivieren
		$cookiePopupElement.find('button.c_m_p_activate_all').on('click', (event) => {
			this.$cookiePopupElement.find('input[type="checkbox"]').prop('checked', true);
		});

		//	Alle Cookies für Type
		$cookiePopupElement.find('.c_m_p_details_type_head input[type="checkbox"]').on('change', function(e){
			var checked = jQuery(this).prop('checked');
			jQuery(this).closest('.c_m_p_details_type').find('.c_m_p_details_type_contents input[type="checkbox"]:not(#onoffswitch_PHPSESSION)').prop('checked', checked);
		});

		//	Details für Cookie-Type
		$cookiePopupElement.find('.c_m_p_details_type_headline').on('click', function(e){
			var $typeBox = jQuery(this).closest('.c_m_p_details_type');
			$typeBox.toggleClass('active');
			$typeBox.find('.c_m_p_details_type_contents').slideToggle(400);
		});

		$cookiePopupElement.find('#c_m_p_details_close').on('click', (event) => {
			this.$cookiePopupElement.find('#c_m_p_start').show();
			this.$cookiePopupElement.find('#c_m_p_details').hide();
		});

		$cookiePopupBackgroundElement.on('click', function (event) {
			$cookiePopupBackgroundElement.fadeOut();
		});
	}

	public init() {

		var ccodes = CookieUtil.getCookie('ccodes');
		this.cookiesAllowed = ccodes !== null ? ccodes.split(',') : []; //'fb,gads,dummy'.split(',');

		this.renderCookiesDetails();

		if (this.cookiesAllowed.length == 0) {
			this.showPopup();
		} else {
			this.executeCodes();
		}
	}

	private saveCookies() {
		var $checkedCookies = this.$cookiePopupElement.find('.c_m_p_details_type_contents input[type="checkbox"]:checked');

		// Übernehmen der Selektion
		this.cookiesAllowed = [];
		for (var i = 0; i < $checkedCookies.length; i++) {
			var val = jQuery($checkedCookies[i]).val().toString();
			this.cookiesAllowed.push(val);
		}

		var cookieExpire = new Date();
		cookieExpire.setTime(cookieExpire.getTime() + 6 * 30 * 24 * 3600 * 1000); // 6 Monate
		CookieUtil.setCookie('ccodes', this.cookiesAllowed.join(), cookieExpire);

		this.executeCodes();
		this.$cookiePopupBackgroundElement.fadeOut();
		this.$cookiePopupElement.fadeOut();

		if (this.chatButtonHidden) {
			jQuery('#web-messenger-container').show();
			jQuery('#web-messenger-shoutout').show();
		}
	}

	public showPopup() {
		let $chatButton = jQuery('#web-messenger-container');
		if ($chatButton.length > 0) {
			$chatButton.hide();
			jQuery('#web-messenger-shoutout').hide();
			this.chatButtonHidden = true;
		}

		this.$cookiePopupBackgroundElement.show();
		this.$cookiePopupElement.show();
	}

	private executeCodes() {
		if (!('ccodes' in window)) {
			return;
		}

		let definitions: CookieCodeDefinition[] = window['ccodes'];

		for (var i = 0; i < definitions.length; i++) {

			var cookieCodeDefinition = definitions[i];
			if (this.cookiesAllowed.indexOf(cookieCodeDefinition.key) === -1) {
				continue;
			}

			// skip executed codes
			if (this.codesExecuted.indexOf(cookieCodeDefinition.key) != -1) {
				continue;
			}
			this.codesExecuted.push(cookieCodeDefinition.key);

			// execute tracking code
			cookieCodeDefinition.code();
		}
	}

	private renderCookiesDetails() {
		if (!('ccodes' in window)) {
			return;
		}

		let lang = jQuery('html').attr('lang') || 'de';
		if (lang.indexOf('-') !== -1) {
			lang = lang.substring(0, lang.indexOf('-'));
		}

		let definitions: CookieCodeDefinition[] = window['ccodes'];

		for (var i = 0; i < definitions.length; i++) {
			var cookieCodeDefinition = definitions[i];
			var $container = this.$cookiePopupElement.find('.c_m_p_details_type_' + cookieCodeDefinition['type']);
			var $content = $container.find('.c_m_p_details_type_contents');
			var html = '<div class="c_m_p_details_type_content">' +
							'<div class="c_m_p_details_type_switch">' +
								'<span class="onoffswitch">' +
									'<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="onoffswitch_' + cookieCodeDefinition['key'] +'" value="' + cookieCodeDefinition['key'] +'"';
			//	Essenzielle Cookies vorankreuzen bzw. gespeicherte Cookies
			if(
				(this.cookiesAllowed.length === 0 && cookieCodeDefinition['type'] == 'E')
				|| this.cookiesAllowed.indexOf(cookieCodeDefinition.key) !== -1
			) {
				html += 'checked';
			}

			html +=					'>' +
									'<label class="onoffswitch-label" for="onoffswitch_' + cookieCodeDefinition['key'] +'"></label>' +
								'</span>' +
							'</div>' +
							'<div class="c_m_p_details_type_content_headline">' + cookieCodeDefinition['title'] + '</div>' +
							'<div class="c_m_p_details_type_content_description">' + cookieCodeDefinition['description'][lang] + '</div>' +
						'</div>'

			$content.append(html);
		}
	}
}

var cookieManager = new CookieManager(jQuery('#c_m_p'), jQuery('#c_m_p_bg'));
cookieManager.init();

window['cookieManager'] = cookieManager;
